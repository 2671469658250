// Vendors
import * as yup from 'yup';

export const tdlr = {
  APP_RULES: {
    tdlrlr: {
      linkService: {
        licenseNumber: yup
          .string()
          .trim()
          .required({ id: 'Field_LicenseNumberRequired' })
      }
    }
  }
};
