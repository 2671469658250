import '@babel/polyfill';
import 'react-app-polyfill/ie11';
import 'whatwg-fetch';
import 'url-search-params-polyfill';

if (!String.prototype.includes) {
  // eslint-disable-next-line
  String.prototype.includes = function() {
    return String.prototype.indexOf.apply(this, arguments) !== -1;
  };
}

if (window.Element && !Element.prototype.closest) {
  Element.prototype.closest = function(s) {
    var matches = (this.document || this.ownerDocument).querySelectorAll(s),
      i,
      el = this;
    do {
      i = matches.length;
      while (--i >= 0 && matches.item(i) !== el) {}
    } while (i < 0 && (el = el.parentElement));
    return el;
  };
}
