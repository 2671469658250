export const getPaymentMethodString = string => {
  const map = {
    CREDIT_CARD: 'CreditCard',
    DEBIT_CARD: 'DebitCard',
    ACH: 'Transfer',
    UNKNOWN: 'Online'
  };

  return map[string] || 'OtherPaymentMethod';
};

export const getPaymentRoute = (route, currentPathname) => {
  if (!route || !currentPathname) return;
  const paymentRoute = currentPathname.substring(0, currentPathname.indexOf('payment') + 7);
  return paymentRoute + route;
};
