import getDeviceDetails from './getDeviceDetails';

export const getChannelTypeFromValue = value => {
  if (!value) return;
  if (value.includes('@')) return 'SMTP';
  if (value.includes(' ')) return 'SMS';
  return 'UNKNOWN';
};

export const requiresDeviceDetails = ({ callbacks }) =>
  callbacks && callbacks.find(callback => callback.type === 'HiddenValueCallback');

export const requiresMFAOption = ({ callbacks }) =>
  callbacks && callbacks.find(callback => callback.type === 'ChoiceCallback');

export const requiresMFACode = ({ callbacks }) =>
  callbacks && callbacks.find(callback => callback.type === 'PasswordCallback');

export const requiresRememberThisDevice = ({ callbacks }) =>
  callbacks && callbacks[0] && callbacks[0].output && callbacks[0].output[0].value === 'Do you want to save?';

export const updateWithDeviceDetails = callbacks => {
  callbacks
    .find(callback => callback.type === 'HiddenValueCallback')
    .input.find(i => i.name === 'IDToken0').value = getDeviceDetails();
  return callbacks;
};

export const updateWithRememberThisDevice = ({ callbacks, rememberThisDevice }) => {
  callbacks
    .find(callback => callback.type === 'ChoiceCallback')
    .input.find(i => i.name === 'IDToken0').value = rememberThisDevice ? 1 : 0;
  return callbacks;
};

export const getMFAOptionsFromCallbacks = callbacks => {
  const choiceCallback = callbacks.find(callback => callback.type === 'ChoiceCallback');
  return choiceCallback
    ? choiceCallback.output
        .find(output => output.name === 'choices')
        .value.map(option => ({ type: getChannelTypeFromValue(option), value: option }))
    : null;
};

export const callbacksWithSelectedMFAIndex = ({ selectedMFAOption, callbacks }) => {
  const choiceCallbackIndex = callbacks.findIndex(callback => callback.type === 'ChoiceCallback');
  const choices = callbacks[choiceCallbackIndex].output.find(output => output.name === 'choices').value;
  const MFAInputIndex = callbacks[choiceCallbackIndex].input.findIndex(input => input.name === 'IDToken0');
  const selectedMFAIndex = choices.indexOf(selectedMFAOption);
  callbacks[choiceCallbackIndex].input[MFAInputIndex].value = selectedMFAIndex;
  return callbacks;
};

export const callbacksWithMFACode = ({ MFACode, callbacks }) => {
  const resendCallbackIndex = callbacks.findIndex(callback => callback.type === 'NameCallback');
  const resendInputIndex = callbacks[resendCallbackIndex].input.findIndex(input => input.name === 'IDToken0');
  callbacks[resendCallbackIndex].input[resendInputIndex].value = 'false';
  const passwordCallbackIndex = callbacks.findIndex(callback => callback.type === 'PasswordCallback');
  const MFAInputIndex = callbacks[passwordCallbackIndex].input.findIndex(input => input.name === 'IDToken0');
  callbacks[passwordCallbackIndex].input[MFAInputIndex].value = MFACode;
  return callbacks;
};

export const callbacksWithMFACodeResend = ({ callbacks }) => {
  const resendCallbackIndex = callbacks.findIndex(callback => callback.type === 'NameCallback');
  const resendInputIndex = callbacks[resendCallbackIndex].input.findIndex(input => input.name === 'IDToken0');
  callbacks[resendCallbackIndex].input[resendInputIndex].value = 'true';
  const passwordCallbackIndex = callbacks.findIndex(callback => callback.type === 'PasswordCallback');
  const MFAInputIndex = callbacks[passwordCallbackIndex].input.findIndex(input => input.name === 'IDToken0');
  callbacks[passwordCallbackIndex].input[MFAInputIndex].value = 'NOT_SET';
  return callbacks;
};
