import * as BON from './BON';
import * as TDLR from './TDLR';

export const agencySlugMap = {
  '16': {
    slug: 'bon',
    '27': 'bnlr'
  },
  '452': {
    slug: 'tdlr',
    TDLR_LR: 'tdlrlr'
  }
};

export const agencyData = { ...BON, ...TDLR };
