import * as yup from 'yup';
import ERRORS from '../errorCopy';
import { DateTime } from 'luxon';

export const REGEX = {
  ALPHANUMERIC: /^[\u00C0-\u00FF\w\-']+$/,
  ALPHABETICAL: /^[\u00C0-\u00FFa-zA-Z\s\-.']+$/,
  PHONE: /^\d{3}-\d{3}-\d{4}$/,
  ZIP_FIVE: /^\d{5}$/,
  LAST_FOUR_SSN: /^\d{4}$/
};

export const makeRequired = (rule, message) => rule.required(message);

export const selectInput = message =>
  yup.object().shape({ value: yup.string().required(message || ERRORS.FIELDS.SELECT_OPTION) });

export const email = ({ required = true } = {}) => {
  const baseEmail = yup
    .string()
    .nullable()
    .email(ERRORS.FIELDS.EMAIL.INVALID);

  if (required) return baseEmail.required(ERRORS.FIELDS.EMAIL.REQUIRED);

  return baseEmail;
};

export const workEmailInUse = yup
  .string()
  .test('work-email-in-use', 'this email is already in use', val => val.substr(val.length - 4) === '.gov');

export const emailMatching = ref =>
  yup
    .string()
    .oneOf([yup.ref(ref)], ERRORS.FIELDS.EMAIL_MATCHING.REQUIRED)
    .required(ERRORS.FIELDS.EMAIL_MATCHING.REQUIRED);

export const password = yup
  .string()
  .trim()
  .min(8, ERRORS.FIELDS.PASSWORD.MIN)
  .max(128, ERRORS.FIELDS.PASSWORD.MAX)
  .required(ERRORS.FIELDS.PASSWORD.REQUIRED);

export const passwordMatching = ref =>
  yup
    .string()
    .oneOf([yup.ref(ref)], ERRORS.FIELDS.PASSWORD_MATCHING.MATCHES)
    .required(ERRORS.FIELDS.PASSWORD_MATCHING.REQUIRED);

export const newPassword = ref =>
  yup
    .string()
    .notOneOf([yup.ref(ref)], ERRORS.FIELDS.NEW_PASSWORD.NO_MATCH)
    .min(8, ERRORS.FIELDS.PASSWORD.MIN)
    .max(128, ERRORS.FIELDS.PASSWORD.MAX)
    .required(ERRORS.FIELDS.NEW_PASSWORD.REQUIRED);

export const contactCode = yup
  .string()
  .min(6, ERRORS.FIELDS.CONTACT_CODE.MIN)
  .required(ERRORS.FIELDS.CONTACT_CODE.REQUIRED);

export const phone = ({ excludeEmptyString = false, required = true } = {}) => {
  const rule = yup.string().matches(REGEX.PHONE, { message: ERRORS.FIELDS.PHONE.VALID, excludeEmptyString });

  return required ? rule.required(ERRORS.FIELDS.PHONE.VALID) : rule;
};

export const phoneType = yup
  .string()
  .oneOf(['SMS', 'TALK'], ERRORS.FIELDS.PHONE_TYPE.REQUIRED)
  .required(ERRORS.FIELDS.PHONE_TYPE.REQUIRED);

export const firstName = yup
  .string()
  .trim()
  .matches(REGEX.ALPHABETICAL, ERRORS.FIELDS.ALPHABETICAL_ONLY)
  .required(ERRORS.FIELDS.FIRST_NAME.REQUIRED);

export const lastName = yup
  .string()
  .trim()
  .matches(REGEX.ALPHABETICAL, ERRORS.FIELDS.ALPHABETICAL_ONLY)
  .required(ERRORS.FIELDS.LAST_NAME.REQUIRED);

export const lastNameMin2Chars = yup
  .string()
  .trim()
  .min(2, ERRORS.FIELDS.MIN)
  .matches(REGEX.ALPHABETICAL, ERRORS.FIELDS.ALPHABETICAL_ONLY)
  .required(ERRORS.FIELDS.LAST_NAME.REQUIRED);

export const driverLicense = yup
  .string()
  .min(8, ERRORS.FIELDS.DRIVER_LICENSE.MIN)
  .required(ERRORS.FIELDS.DRIVER_LICENSE.REQUIRED);

export const auditNumber = yup
  .string()
  .min(20, ERRORS.FIELDS.AUDIT_NUMBER.MIN)
  .required(ERRORS.FIELDS.AUDIT_NUMBER.REQUIRED);

export const date = ({ required, format = 'MM/dd/yyyy', min, max }) =>
  yup.mixed().test('date', ERRORS.FIELDS.DATE.FORMAT, function(value) {
    const { path, createError } = this;

    if (required && !value) return createError({ path, message: required });

    const dateEntered = DateTime.fromFormat(value, format);

    if (value && dateEntered.invalid) return createError({ path, message: ERRORS.FIELDS.DATE.FORMAT });

    if (min && min.date) {
      if (format === 'MM/yy') {
        const minDate = min.date.minus({ months: 1 });
        if (dateEntered.diff(minDate, 'months').values.months < 0)
          return createError({ path, message: min.message || 'Date is too early.' });
      }

      const minDate = min.date.minus({ days: 1 });
      if (dateEntered.diff(minDate, 'days').values.days < 0)
        return createError({ path, message: min.message || 'Date is too early.' });
    }

    if (max && max.date) {
      if (format === 'MM/yy') {
        const maxDate = max.date.plus({ months: 1 });
        if (dateEntered.diff(maxDate, 'months').values.months > 0)
          return createError({ path, message: ERRORS.FIELDS.DATE.PAST });
      }

      const maxDate = max.date;
      if (dateEntered.diff(maxDate, 'days').values.days > 0)
        return createError({ path, message: ERRORS.FIELDS.DATE.PAST });
    }

    if (min && typeof min.age === 'number') {
      const dateMin = DateTime.local().minus({ years: min.age });
      if (dateEntered.diff(dateMin, 'years').values.years > 0)
        return createError({ path, message: min.message || 'Date is too early.' });
    }

    if (max && typeof max.age === 'number') {
      const dateMax = DateTime.local().minus({ years: max.age });
      if (dateEntered.diff(dateMax, 'years').values.years < 0)
        return createError({ path, message: max.message || ERRORS.FIELDS.DATE.PAST });
    }

    return true;
  });

export const dob = date({
  required: ERRORS.FIELDS.DOB.REQUIRED,
  min: { age: 18, message: ERRORS.FIELDS.DOB.MAX },
  max: { age: 120, message: ERRORS.FIELDS.DOB.MIN }
});

export const creditcard = {
  name: yup
    .string()
    .trim()
    .matches(REGEX.ALPHABETICAL, ERRORS.FIELDS.ALPHABETICAL_ONLY)
    .required(ERRORS.FIELDS.CREDITCARD.NAME_ON_CARD.REQUIRED),
  expiration: () =>
    date({
      required: ERRORS.FIELDS.CREDITCARD.EXPIRATION.REQUIRED,
      format: 'MM/yy',
      min: { date: DateTime.local(), message: ERRORS.FIELDS.CREDITCARD.EXPIRATION.MIN }
    }),
  number: yup
    .string()
    .min(12, ERRORS.FIELDS.CREDITCARD.NUMBER.VALID)
    .max(19, ERRORS.FIELDS.CREDITCARD.NUMBER.VALID)
    .required(ERRORS.FIELDS.CREDITCARD.NUMBER.REQUIRED),
  cvv: yup
    .string()
    .min(3, ERRORS.FIELDS.CREDITCARD.CVV.VALID)
    .max(4, ERRORS.FIELDS.CREDITCARD.CVV.VALID)
    .required(ERRORS.FIELDS.CREDITCARD.CVV.REQUIRED)
};

export const ach = {
  name: yup
    .string()
    .trim()
    .matches(REGEX.ALPHABETICAL, ERRORS.FIELDS.ALPHABETICAL_ONLY)
    .required(ERRORS.FIELDS.ACH.NAME_ON_CHECK.REQUIRED),
  routing: yup.string().required(ERRORS.FIELDS.ACH.ABA_ROUTING.REQUIRED),
  confirmRouting: ref =>
    yup
      .string()
      .oneOf([yup.ref(ref)], ERRORS.FIELDS.ACH.ABA_ROUTING.CONFIRM)
      .required(ERRORS.FIELDS.ACH.ABA_ROUTING.CONFIRM),

  account: yup.string().required(ERRORS.FIELDS.ACH.ACCOUNT_NUMBER.REQUIRED),
  confirmAccount: ref =>
    yup
      .string()
      .oneOf([yup.ref(ref)], ERRORS.FIELDS.ACH.ACCOUNT_NUMBER.CONFIRM)
      .required(ERRORS.FIELDS.ACH.ACCOUNT_NUMBER.CONFIRM)
};

// TODO: Resolve duplicates once we normalize the field name for lastFourSSN.
export const lastFourSSN = yup
  .string()
  .min(4, ERRORS.FIELDS.LAST_FOUR_SSN.MIN)
  .matches(REGEX.LAST_FOUR_SSN, ERRORS.FIELDS.LAST_FOUR_SSN.REQUIRED)
  .required(ERRORS.FIELDS.LAST_FOUR_SSN.REQUIRED);

export const ssn = lastFourSSN;

export const requiredCheckbox = yup.boolean().oneOf([true], true);

export const streetAddress = yup
  .string()
  .trim()
  .nullable()
  .min(3, ERRORS.FIELDS.ADDRESS.STREET_ADDRESS.REQUIRED);

export const addressLine2 = yup.string().nullable();

export const city = yup
  .string()
  .trim()
  .nullable()
  .matches(REGEX.ALPHABETICAL, ERRORS.FIELDS.ALPHABETICAL_ONLY)
  .min(3, ERRORS.FIELDS.ADDRESS.CITY.REQUIRED)
  .required(ERRORS.FIELDS.ADDRESS.CITY.REQUIRED);

export const state = yup.object().shape({
  value: yup.string().required(ERRORS.FIELDS.ADDRESS.STATE.REQUIRED)
});

export const zipFive = ({ required = true, excludeEmptyString = false } = {}) => {
  const rule = yup
    .string()
    .nullable()
    .matches(REGEX.ZIP_FIVE, { message: ERRORS.FIELDS.ADDRESS.ZIP_FIVE.DIGITS, excludeEmptyString });
  return required ? rule.required(ERRORS.FIELDS.ADDRESS.ZIP_FIVE.REQUIRED) : rule;
};

export const country = yup.object().shape({
  code: yup.string().required(ERRORS.FIELDS.ADDRESS.COUNTRY.REQUIRED)
});

export const securityQuestion = yup
  .object()
  .shape({ quid: yup.string().required(ERRORS.FIELDS.SECURITY_QUESTIONS.QUESTION.REQUIRED) });

export const identityQuestion = yup.string().required(ERRORS.FIELDS.IDENTITY_QUESTION.REQUIRED);

export const securityAnswer = yup
  .string()
  .trim()
  .min(3, ERRORS.FIELDS.SECURITY_QUESTIONS.ANSWER.MIN)
  .required(ERRORS.FIELDS.SECURITY_QUESTIONS.ANSWER.REQUIRED);

export const min = number => yup.string().min(number, ERRORS.FIELDS.MIN_LENGTH(number));

export const max = number => yup.string().max(number, ERRORS.FIELDS.MAX_LENGTH(number));

export const MFAOption = yup.string().required(ERRORS.FIELDS.MFA_OPTIONS.REQUIRED);

export const yesNoRadioGroup = yup
  .boolean()
  .nullable()
  .required(ERRORS.FIELDS.REQUIRED_YES_NO);
