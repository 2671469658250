import { agencySlugMap, agencyData } from '../data/agencyData';
import { agencyRules } from '../validation/rules';

export const getAgencySlugByCode = code => agencySlugMap[code].slug;

export const getAgencyCodeBySlug = slug => agencyData[slug].agencyCode;

export const getAppSlugByCodes = (agencyCode, appCode) => agencySlugMap[agencyCode][appCode];

export const getAgencyDataByCode = code => agencyData[getAgencySlugByCode(code)];

export const getAppDataByCodes = (agencyCode, appCode) => agencyData[getAppSlugByCodes(agencyCode, appCode)];

export const getAgencyDataBySlug = slug => agencyData[slug];

export const getAppDataBySlug = slug => agencyData[slug];

export const getAppValidationRulesBySlugs = (agencySlug, appSlug) => agencyRules[agencySlug].APP_RULES[appSlug];
