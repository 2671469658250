export const stripDashes = (string: string) => string.replace(/-/g, '');

export const capitalize = string =>
  string
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.substr(1))
    .join(' ');

export function debounce(func, wait, immediate) {
  let timeout;

  return function() {
    const context = this;
    const args = arguments;
    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

// https://stackoverflow.com/questions/14810506/map-function-for-objects-instead-of-arrays
export function objectMap(object, mapFunction) {
  return Object.keys(object).reduce(function(result, key) {
    result[key] = mapFunction(object[key]);
    return result;
  }, {});
}

export const getObjectValueByPath = (object, path) => path.split('.').reduce((acc = {}, curr) => acc[curr], object);

export function setObjectPropertyByPath(object, path, value) {
  path = path.split('.');

  while (path.length > 1) {
    const position = path.shift();
    if (!object[position]) object[position] = {};
    object = object[position];
  }

  return (object[path.shift()] = value);
}

export const cloneObject = object => JSON.parse(JSON.stringify(object));

// See explanation here: https://stackoverflow.com/questions/4994201/is-object-empty
export const isEmptyObject = obj => Object.getOwnPropertyNames(obj).length === 0;

export const convertContentArrayToString = contentArray =>
  contentArray.reduce((acc, curr) => {
    acc += curr.props.children;
    return acc;
  }, '');

export const convertStringToBoolean = value => (value ? value === 'true' || value === 'Y' : null);

export const shallowCompare = (obj1, obj2) =>
  Object.keys(obj1).length === Object.keys(obj2).length && Object.keys(obj1).every(key => obj1[key] == obj2[key]); // eslint-disable-line eqeqeq
